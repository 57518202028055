import { render, staticRenderFns } from "./CompanyProposalSideModal.vue?vue&type=template&id=76be99e2&scoped=true&"
import script from "./CompanyProposalSideModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CompanyProposalSideModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CompanyProposalSideModal.vue?vue&type=style&index=0&id=76be99e2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76be99e2",
  null
  
)

export default component.exports